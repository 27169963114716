



















































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { Form, FormItem, Checkbox, Dialog } from "element-ui";
import FormItemBox from "@/components/sign/FormItemBox.vue";
import signjs from "@/components/sign/index";

@Component({
    components: {
        "el-form": Form,
        "al-form-item": FormItemBox,
        "el-form-item": FormItem,
        "el-checkbox": Checkbox,
        "el-dialog": Dialog
    }
})
export default class basicRegistForm extends Vue {
    @Action("getSetToken") setToken: any;
    @Getter("getToken") token: any;
    @Getter("getLang") lang: any;

    @Prop({ type: String, required: true, default: () => ({}) })
    childName!: string; //表单数据

    declare: boolean = false; //声明弹窗
    checked: boolean = this.$route.name == "register" ? false : true; //是否勾选声明
    formIsShow: boolean = true;

    get registForm() {
        let registForm: object = [
            {
                ref: "name",
                formkey: "name",
                type: "input",
                classname: "regist-name",
                label: this.$t("sign.regist_uname_label"),
                placeholder: this.$t("sign.regist_uname_placeholder")
            },
            {
                ref: "email",
                formkey: "email",
                type: "input",
                classname: "regist-email",
                label: this.$t("sign.email"),
                placeholder: this.$t("sign.emailPlaceholder")
            },
            {
                ref: "password",
                formkey: "password",
                type: "password",
                classname: "regist-password",
                label: this.$t("sign.pwd"),
                placeholder: this.$t("sign.regist_pwd_placeholder")
            },
            {
                ref: "password_confirmation",
                formkey: "password_confirmation",
                type: "password",
                classname: "regist-password_confirmation",
                label: this.$t("sign.pwd_confirmLabel"),
                placeholder: this.$t("sign.regist_pwd_confirm_placeholder")
            }
        ];
        return registForm;
    }

    /* 表单数据 */
    registFormData = {
        name: "",
        email: "",
        password: "",
        password_confirmation: ""
    };

    /* 邮箱格式验证 */
    private validateEmailRule(rule: any, value: any, callback: any) {
        let emailreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[-|\_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
        if (value === "") {
            callback(
                new Error(this.$t("sign.regist_email_rule_notnull") as string)
            );
        }
        // else if (!emailreg.test(value)) {
        //     callback(
        //         new Error(this.$t("sign.regist_email_rule_format") as string)
        //     );
        // }
        else {
            callback();
        }
    }

    /* 密码验证 */
    private validatePwdRule(rule: any, value: any, callback: any) {
        let pwdreg = /^[a-zA-Z0-9]{8,20}$/;
        if (value === "") {
            callback(
                new Error(this.$t("sign.regist_pwd_rule_notnull") as string)
            );
        } else if (!pwdreg.test(value)) {
            callback(
                new Error(this.$t("sign.regist_pwd_rule_format") as string)
            );
        } else {
            callback();
        }
    }

    /* 二次密码验证 */
    private validatePwdConfirmRule(rule: any, value: any, callback: any) {
        if (value === "") {
            callback(
                new Error(
                    this.$t("sign.regist_pwd_confirm_rule_notnull") as string
                )
            );
        } else if (value !== this.registFormData.password) {
            callback(
                new Error(
                    this.$t("sign.regist_pwd_confirm_rule_format") as string
                )
            );
        } else {
            callback();
        }
    }

    /* 注册表单验证规则 */
    get registFormRules() {
        let opt = {
            name: [
                {
                    required: true,
                    message: this.$t("sign.regist_uname_rule_notnull"),
                    trigger: "blur"
                }
            ],
            email: [
                {
                    required: true,
                    message: this.$t("sign.regist_email_rule_notnull"),
                    trigger: "blur"
                },
                { validator: this.validateEmailRule, trigger: "blur" }
            ],
            password: [
                {
                    required: true,
                    message: this.$t("sign.regist_pwd_rule_notnull"),
                    trigger: "blur"
                },
                { validator: this.validatePwdRule, trigger: "blur" }
            ],
            password_confirmation: [
                {
                    required: true,
                    message: this.$t("sign.regist_pwd_confirm_rule_notnull"),
                    trigger: "blur"
                },
                { validator: this.validatePwdConfirmRule, trigger: "blur" }
            ]
        };
        return opt;
    }

    //提交表单
    sub(formName: string, callback: any, isloading?: any) {
        // 表单验证
        signjs.validateForm(formName, this.$refs).then(res => {
            if (res && this.checked) {
                isloading(); //打开loading遮罩
                //注册前处理数据
                this.setFormData(this.registFormData).then((res: any) => {
                    //调用注册方法
                    signjs.regist(res).then((res: any) => {
                        this.$notify({
                            title: res.status === 0 ? "成功" : "失败",
                            message: res.msg,
                            type: res.status === 0 ? "success" : "error"
                        });
                        if (callback) {
                            callback(res.status, res.data);
                        } else {
                            console.error("Regist:No Callback");
                        }
                    });
                });
            } else if (!this.checked) {
                //@ts-ignore
                this.$notify.error({
                    //@ts-ignore
                    title: this.$t("scaffold.error"),
                    message: this.$t("sign.agree_declare")
                });
            }
        });
    }

    //重置表单数据
    resetForm(formName: string) {
        //@ts-ignore
        signjs.resetForm(formName, this.$refs);
    }

    /* 提交前设置提交数据 */
    async setFormData(data: any) {
        const subdata = JSON.parse(JSON.stringify(data));
        if (sessionStorage.getItem("channel_id")) {
            subdata.channel_id = sessionStorage.getItem("channel_id");
        }
        return subdata;
    }

    // 表单单项验证
    validItem(formname: string, formItem: string | object, callback: any) {
        signjs
            .validateFormItem(formname, formItem, this.$refs)
            .then((res: any) => {
                callback(res);
            });
    }

    //打开声明弹窗
    openDeclare() {
        this.declare = true;
    }

    test(formname?: string) {
        this.registFormData = {
            name: "al1995",
            email: "zhaozinan@ittn.com.cn",
            password: "12341234",
            password_confirmation: "12341234"
        };
    }

    @Watch("lang")
    langChange(nd: any, od: any) {
        this.formIsShow = false;
        setTimeout(() => {
            this.formIsShow = true;
        }, 10);
    }
}
